@use './custom.scss';
@use './toast.scss';

@font-face {
  font-family: 'Lazy Dog';
  src: url('../assets/fonts/Lazydog.otf');
}
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-Regular.ttf')
}
@font-face {
  font-family: 'Aldo';
  src: url('../assets/fonts/Aldo.ttf')
}
@font-face {
  font-family: 'Sans Pro';
  src: url('../assets/fonts/SansPro-Semibold.otf')
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100vh;
}
.MuiAutocomplete-popper {
  z-index: 10001 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.modal {
  z-index: 999999;
}

.enviro-filter-modal {
  color: #FFF;
  justify-content: center;
  .modal-content {
    max-width: 400px;
    border-radius: 20px;
    text-align: center;
    background-color: #184d66;

    .modal-body {
      img {
        width: 5.5vw;
        display: block;
        margin: 0 auto 20px;
      }
      textarea {
        width: -webkit-fill-available;
      }
    }
    .modal-header {
      border-bottom: unset;
      .close {
        color: #fff;
      }
    }
    .modal-footer {
      border-top: unset;

      .btn-primary {
        background-color: #5dc5c4;
      }
    }
  }
}

.btn-disable {
  opacity: .65;
}