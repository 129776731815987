$dot-width: 10px;
$dot-color: #3b5998;
$speed: 1.5s;

:root {
  overflow: hidden !important;
}
.typing {
  position: relative;
  width: 16%;
  margin: 20px 0 20px 10px;
  span {
    content: '';
    animation: blink $speed infinite;
    animation-fill-mode: both;
    height: $dot-width;
    width: $dot-width;
    background: $dot-color;
    position: absolute;
    left:0;
    top:0;
    border-radius: 50%;
    
    &:nth-child(2) {
      animation-delay: .2s;
      margin-left: $dot-width * 1.5;
    }
    
    &:nth-child(3) {
      animation-delay: .4s;
      margin-left: $dot-width * 3;
    }
  }
}

@keyframes blink {
  0% {
    opacity: .1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}

.fs-15 {
  font-size: 15px !important;
}

.enviro-ai {
  height: 100vh;
  button {
    font-family: 'Sans Pro';
  }
  .label {
    background-color: #000000;
    color: #ffff;
    border-radius: 20px;
    width: 100%;
    padding: 5px 10px;
    text-align: center;
    margin-bottom: 0 !important;
  }

  >nav button {
    display: flex;
    gap: 3px;
    border-radius: 6px;
    align-items: center;
    border-color: #DDC5B3;
    font-family: 'Sans Pro';
    > svg {
      color: #CBDFF8;
      :hover {
        color: #5DC5C4;
      }
    } 
  }

  .question {
    .preview-img, .preview-file {
      margin-left: auto;
      margin-bottom: 5px;
      width: max-content;
      }
  }

  .preview-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    min-width: 64px;
    overflow: hidden;
    position: relative;
    // background-color: #f0f0f0;
    border: 1px solid lightgray;
    border-radius: 10px;
    > img {
      width: 64px;
      display: block;
      position: absolute;
    }
  }

  .preview-file {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 7px;
    gap: 7px;
    margin-right: 4px;

    p {
      margin-bottom: 0 !important;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 12px;
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50% !important;
    visibility: hidden;
    padding: 0;
    display: flex;
    justify-content: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }
  
  .preview-img:hover, .preview-file:hover {
    .close-button {
      visibility: visible;
      opacity: 1;
      color: gray;
    }
  }
  .searchbox {
    border-radius: 30px 30px 30px 30px;
    padding: 10px;
    background-color: #F4F4F4;

    textarea {
      border-width: 0;
      height: 34px;
      overflow: hidden; // Hide scrollbar
      resize: none; // Prevent manual resizing
      &:focus {
        border: 0;
        box-shadow: unset;
        outline: 0 none;
      }
    }

    button {
      border-radius: 5px;
    }

    .arrow-btn {
      color: #2C190E;
      font-weight: 600;
      background-color: #DDC5B3;
      border-color: #DDC5B3;
      min-width: 75px;
    }
    
    .voice-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      border-radius: 50%;
      width: 40px;  // Adjust the width to your preference
      height: 30px;
      margin-left: 10px // Adjust the height to create the oval shape
    }
  }

  .newchat-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 82%;
    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // height: 55%;
      margin: auto;

      .help-question {
        font-family: 'Aldo';
        color: #2C190E;
      }
    }
    .suggestion-buttons {
      display: grid;
      height: auto;
      grid-template-columns: 50% 50%;
      width: 55%;
      grid-gap: 10px;
      margin: 10px auto;

      .prompt-btn {
        height: auto;
        border: 0;
        border-radius: 6px;
        background-color: #CBDFF8;
        p {
          color: #2C190E;
          margin-bottom: 0;
          margin: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:hover {
          p {
            color: white;
          }
          .arrow-btn {
            display: block;
          }
        }

        .arrow-btn {
          display: none;
        }

      }
    }
  }

  .quick-buttons {
    margin: 10px auto 10px;
    display: flex;
    justify-content: center;
    font-family: 'Sans Pro';
  }

  .title {
    font-family: 'Sans Pro';
    margin-right: 4rem;
    color: white;
  }

  .header {
    font-family: 'Sans Pro';
    padding-left: 4rem;
    height: 4rem;
    background-color: #2C190E;

    .nav-link {
      color: white;
      &.active {
        color: white;
      }
    }
  }
  .card {
    background: #fff;
    transition: .5s;
    border: 0;
    position: relative;
    width: 100%;
  }

  .fixed-top {
    z-index: 9;
  }
  
  > .row {
    margin-top: 64px;
  }

  @keyframes grow {
    0% { max-height: var(--lineHeight); }
    100% { max-height: calc(var(--lineHeight) * var(--lines)); }
  }

  @keyframes carriageReturn {
    0% { top: 0; }
    100% { top: calc(var(--lineHeight) * var(--lines)); }
  }

  @keyframes type {
    0% { width: 100%; }
    100% { width: 0%; }
  }

  @keyframes caret {
    0% { color: var(--bgColor); }
    100% { color: black; }
  }

  .chat-app {
    .chat {
      margin-left: 200px;
      border-left: 1px solid #eaeaea;
      .chat-header {
        padding: 15px 20px;
        border-bottom: 2px solid #f4f7f6;
    
        img {
          float: left;
          border-radius: 40px;
          width: 40px;
        }
    
        .chat-about {
          float: left;
          padding-left: 10px;
        }
      }
    
      .chat-history {
        padding: 20px;
        border-bottom: 2px solid #fff;
        overflow-x: auto;
        overflow-y: scroll;
        height: 100%;
    
        &-wrapper {
          min-height: 50%;
          max-height: 80%;
        }

        p:not(.label) {
          margin-bottom: 1.5rem;
        }
    
        ul {
          padding: 0;
          &:not(.question) {
            margin-bottom: 1.5rem;
          }

          li {
            list-style: none;
            // margin-bottom: 30px;
    
            &.answer {
              width: 32%;

              .my-message * {
                font-size: 16px;
                margin-bottom: 1.5rem;
                line-height: 1.5rem;
              }
              ul {
                padding-inline-start: 40px;

                li {
                  list-style: disc;
                }
              }
    
              &:nth-child(1) .my-message {
                color: black;
                width: 100%;
                background-color: #e7ded1;
                --bgColor: #e7ded1;
              }
    
              &:nth-child(2) .my-message {
                color: black;
                width: 100%;
                background-color: #d1ffff;
                --bgColor: #d1ffff;
              }
    
              &:nth-child(3) .my-message {
                width: 100%;
                background-color: #f2f2f2;
                --bgColor: #f2f2f2;
              }
            }
          }
        }
    
        .message {
          color: #444;
          padding: 18px 20px;
          line-height: 26px;
          font-size: 16px;
          border-radius: 7px;
          display: inline-block;
          position: relative;
        }
    
        ul>li.answer:nth-child(1), ul>li.answer:nth-child(2), ul>li.answer:nth-child(3) {
          .my-message.anim .answer-description {
            width: -webkit-fill-available;
            --lines: 1000;
            --lineHeight: 1.5rem;
            --timePerLine: .2s;
            --widthCh: 40;
            --width: 100%;
            /* do not touch the time property!!! */
            --time: calc(var(--lines) * var(--timePerLine));
            animation: grow var(--time) steps(var(--lines));
            animation-fill-mode: forwards;
            line-height: var(--lineHeight);
            max-height: var(--lineHeight);
            overflow: hidden;
            position: relative;
            width: var(--width);

            /* Add this to target h tags */
            h1, h2, h3, h4, h5, h6 {
              font-weight: 500;
            }
          }
        }

        ul>li.answer:nth-child(1), ul>li.answer:nth-child(2), ul>li.answer:nth-child(3) {
          .my-message.anim .answer-description {
            &::before {
              content: "";
              animation:
                type var(--timePerLine) linear none,
                carriageReturn var(--time) steps(var(--lines)) var(--lines),
                caret 1s steps(2) infinite;
              background: var(--bgColor);
              border-left: 2px solid;
              bottom: 0;
              height: 2rem;
              position: absolute;
              right: 0;
              width: 100%;
              border-left-width: 0;
              animation: type var(--timePerLine) steps(var(--widthCh)) infinite,
              carriageReturn var(--time) steps(var(--lines)) var(--lines),
              caret 1s steps(2) infinite;
            }

            /* Add this to target h tags */
            h1, h2, h3, h4, h5, h6 {
              font-weight: 500;
            }
          }
        }

        .message-datetime{
          text-align: right;
          font-size: 12px;
          color: gray;
        }
    
        .other-message {
          font-weight: 500;
        }
        
        .query-content {
          background-color: #66ffff;
          border: 2px solid black;
          border-radius: 10px;
        }
      }
    
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 3rem);
      overflow: scroll;
    
      &::-webkit-scrollbar {
        width: 0;
      }
    
      .chat-message {
        padding: 10px 0;
        margin: auto;
      }
      .message-chatSearch {
        max-width: 80%;
      }

      .chatSearch {
        max-width: 60%;
      }
    
      .chat-message-wrapper {
        // position: sticky;
        bottom: 0;
        background-color: #FFF;
        height: auto;

        .active-profile-label {
          display: flex;
          align-items: center;
          text-align: center;
          gap: 8px;
          font-weight: bold;
          font-size: 15px;
          background-color: #5DC5C4;
          color: #2C190E;

          .dot-icon {
            height: 20px;
            width: 20px;
            min-width: 20px;
            background-color: #CBDFF8;
            border-radius: 50%;
            display: block;
          }
        }
      }
    }
    .people-list {
      -moz-transition: .5s;
      -o-transition: .5s;
      -webkit-transition: .5s;
      transition: .5s;
      width: 200px;
      position: fixed;
      left: 0;
      top: 64px;
      padding: 0 20px 20px;
      height: -webkit-fill-available;
      overflow-y: scroll;
      z-index: 7;

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #D3D3D3;
        border-radius: 10px;
      }
      
      .saved-chat-btn {
        position: sticky;
        top: 0;
        padding: 20px 0 10px;
        margin: 0 -7px;
        z-index: 1;
        background: white;
      }

      .chat-datetime {
        margin: 0 -7px 0;
        color: #8ebbf7;
        padding: 10px 10px 5px;
        font-weight: 500;
        font-size: 12px;
        border-bottom: 3px solid #f2f2f2;
      }
    
      .chat-list {
        margin: 0 -7px;
        li {
          list-style: none;
          padding: 0 7px;
    
          &:hover, &.active {
            background: #efefef;
            border-radius: 10px;
          }
    
          .name {
            font-size: 15px;
          }

          .clipped-name {
            font-size: 13px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
    
          img {
            width: 45px;
            border-radius: 50%;
          }
        }
      }
    
      img {
        float: left;
        border-radius: 50%;
      }
    
      .about {
        float: left;
      }
    
      .status {
        color: #999;
        font-size: 13px;
      }
    }    
  }

  .max-w-80 {
    max-width: 80%;
  }

  .max-w-95 {
    max-width: 95%;
  }
  
  .online,
  .offline,
  .me {
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle
  }
  
  .online {
    color: #86c541
  }
  
  .offline {
    color: #e47297
  }
  
  .me {
    color: #1d8ecd
  }
  
  .float-right {
    float: right
  }
  
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
  }
  
  @media only screen and (max-width: 767px) {
    .chat-app {
      .people-list {
        height: 465px;
        width: 100%;
        overflow-x: auto;
        background: #fff;
        left: -400px;
        display: none;
    
        &.open {
          left: 0;
        }
      }
    
      .chat {
        margin: 0;
    
        .chat-header {
          border-radius: 0.55rem 0.55rem 0 0;
        }
    
        .chat-history {
          height: 300px;
          overflow-x: auto;
        }
      }
    }    
  }
  
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .chat-app {
      .chat-list {
        height: 650px;
        overflow-x: auto;
      }
    
      .chat-history {
        height: 600px;
        overflow-x: auto;
      }
    }    
  }
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .chat-app {
      .chat-list {
        height: 480px;
        overflow-x: auto;
      }
    
      .chat-history {
        height: calc(100vh - 350px);
        overflow-x: auto;
      }
    }    
  }
}
